<template lang="pug">
  div.slot-table__item.slot-table__item_wrap
    .slot-table__cell.slot-table__cell_time
      b Total
    .slot-table__cell.slot-table__cell_quant
      b {{ totalQuantity }}
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
  },

  computed: {
    totalQuantity() {
      return this.items.reduce((a, b) => a + b.quantity, 0)
    }
  }
}
</script>